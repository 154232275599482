// based on
// https://github.com/valor-software/ngx-bootstrap/issues/579#issuecomment-269818270
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { ConfirmationModal } from '../widgets/confirmation-modal'

@Injectable()
export class ConfirmationService {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'modal' has no initializer and is not def... Remove this comment to see the full error message
  private modal: ConfirmationModal

  public initialize (modal: ConfirmationModal) {
    this.modal = modal
  }

  public show (content: string, title?: string, isInputForm: boolean = false, allowCommentsAndAttachments: boolean = false, payload: { value: string | undefined; comments: string | undefined; attachments: any[] } | undefined = undefined): Observable<any> {
    return this.modal.show(content, title, isInputForm, allowCommentsAndAttachments, payload)
  }

  public hide () {
    this.modal.hide()
  }

  public getInputValue(){
    return this.modal.getInputValue()
  }

  public getPayload() {
    return this.modal.getPayload()
  }

  public clearInputValue(){
    this.modal.clearInputValue()
  }
}
