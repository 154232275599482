<ngx-datatable class='material striped'
  [loadingIndicator]="loading"
  [rows]="_rows"
  [columns]="_columns"
  [sorts]="_sorts"

  [count]="_rows.length"
  [limit]="_limit"

  [headerHeight]="40"
  [footerHeight]="0"
  [rowHeight]="'auto'"

  [columnMode]="'force'"
  [selectionType]="false"
  [externalPaging]="true"
  [externalSorting]="true"

  [cssClasses]="_iconsConfig"
  [messages]="{
      emptyMessage: 'COMMON.MSG_NO_DATA' | translate,
      totalMessage: 'COMMON.TOTAL' | translate,
      selectedMessage: ''
    }"

  (sort)="onSort($event)"
>

<!--
  <ngx-datatable-footer>
    <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-curPage="curPage"
        let-offset="offset"
        let-selectedCount="selectedCount">
      <div style="padding: 5px 10px">
        {{ 'COMMON.TOTAL' | translate }}{{ rowCount }}
        <pagination class="pagination-sm"
          [boundaryLinks]="true"
          [totalItems]="rowCount"
          [itemsPerPage]="pageSize"
          [ngModel]="curPage"
          (pageChanged)="changePage"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;">
        </pagination>
      </div>
    </ng-template>
  </ngx-datatable-footer>
-->

</ngx-datatable>

<div class="clearfix">
  <span class="total-count"><strong>Total: {{ _count }}</strong></span>
  <pagination
    [boundaryLinks]="true"
    [totalItems]="_count"
    [maxSize]="5"
    [(ngModel)]="_page"
    [itemsPerPage]="_limit"
    [rotate]="false"
    (pageChanged)= "onPage($event)"
    class="pull-right"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
    *ngIf="_count">
  </pagination>
</div>

<ng-template #i18nColumnHeader let-column="column" let-sortFn="sortFn">
  <strong (click)="sortFn()">{{ column.name | translate }}</strong>
</ng-template>

<ng-template #i18nCenteredColumnHeader let-column="column" let-sortFn="sortFn">
  <div class="header-center">
    <strong (click)="sortFn()">{{ column.name | translate }}</strong>
  </div>
</ng-template>

<ng-template #hideColumnHeader>
</ng-template>

<ng-template #boolCell let-value="value">
  <div class="text-center" *ngIf="!!value">
    <i class="fa fa-check" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template #devicesCell let-value="value">
  <div class="text-center device-list">
    <i class="fa"
        aria-hidden="true"
        *ngFor="let device of value"
        [ngClass]="{ 'fa-android': device.type === 'android', 'fa-apple': device.type === 'ios', 'fa-laptop': device.type === 'web' }"
        [tooltip]="device.type"
        placement="bottom"></i>
  </div>
</ng-template>

<ng-template #defaultLabelCell let-value="value">
  <span class="label label-default">
    {{ value }}
  </span>
</ng-template>

<ng-template #memberTenantRole let-value="value">
  <span class="label label-default">
    {{ value[0] | tenantRole }}
  </span>
</ng-template>

<ng-template #defaultLabelsCell let-value="value">
  <span class="label label-default" *ngFor="let item of value">
    {{ item }}
  </span>
</ng-template>

<ng-template #defaultBadgeCell let-value="value">
  <div class="text-center">
    <span class="badge label-default" *ngIf="value > 0">
      {{ value }}
    </span>
  </div>
</ng-template>

<ng-template #infoBadgeCell let-value="value">
  <div class="text-center">
    <span [ngClass]="{ 'badge label-info': (value > 0) }">
      {{ value }}
    </span>
  </div>
</ng-template>

<ng-template #warningBadgeCell let-value="value">
  <div class="text-center">
    <span [ngClass]="{ 'badge label-warning': (value > 0) }">
      {{ value }}
    </span>
  </div>
</ng-template>

<ng-template #dangerBadgeCell let-value="value">
  <div class="text-center">
    <span [ngClass]="{ 'badge label-danger': (value > 0) }">
      {{ value }}
    </span>
  </div>
</ng-template>

<ng-template #batchJobStatusCell let-row="row" let-value="value">
  <span class="label label-default"
      tooltip="{{ row.failure | translateError }}"
      container="body"
      [ngClass]="value | batchJobStatusLabel">
    {{ value | batchJobStatus }}
  </span>
</ng-template>

<ng-template #batchJobProgressCell let-value="value">
  <span *ngIf="!value.total">-</span>
  <progressbar
      class="progress-striped"
      *ngIf="value.total"
      [max]="value.total"
      [value]="value.step"
      [tooltip]="(value.step | percentage:value.total:true) + '%'"
      container="body"
      placement="bottom">
    {{ value.step }} / {{ value.total }}
    </progressbar>
</ng-template>

<ng-template #errorsDetailCell let-value="value">
  <batch-job-error-popover [errorsDetail]="value"></batch-job-error-popover>
</ng-template>

<ng-template #roleCell let-value="value">
  <span class="label label-default">
    {{ value | tenantRole }}
  </span>
</ng-template>

<ng-template #schoolShiftCell let-value="value">
  <span class="label"
      [ngClass]="value | schoolShiftLabel">
    {{ value | schoolShift }}
  </span>
</ng-template>

<ng-template #genderCell let-value="value">
  <span class="label"
      [ngClass]="value | genderLabel">
    {{ value | gender }}
  </span>
</ng-template>

<ng-template #topicAudienceCell let-value="value">
  <span class="label label-default" *ngIf="value.guardian">
    {{ 'guardian' | tenantRole }}
  </span>
  <span class="label label-default" *ngIf="value.student">
    {{ 'student' | tenantRole }}
  </span>
</ng-template>

<ng-template #topicRecipientsCell let-value="value" let-column="column" let-row="row">
  <span class="label label-default" *ngFor="let item of value">
    {{ item.type | recipientType }}
  </span>

  <a href="javascript:void(0)" (click)="column.onClick(row, value)" [ngClass]="'strong'" style="margin-left: 4px;">Ver todos</a>
</ng-template>

<ng-template #contactCell let-value="value">
  <div *ngIf="value">
    <span class="label label-contact" [ngClass]="value.tag | contactTagLabel">
      <i class="fa fa-fw" aria-hidden="true" [ngClass]="value.type | contactTypeIcon"></i>
      {{ value.tag | contactTag }}
    </span>
    {{ value.value }}
  </div>
</ng-template>

<ng-template #hrefRouterCell let-value="value" let-column="column" let-row="row">
  <a [routerLink]="column.routerLink(row, value)"
      [queryParams]="column.routerParams(row, value)"
      [ngClass]="column.isPrimary ? 'strong' : ''">
      <!-- not implemented since it causes page refresh on links without newTab -->
      <!-- target="{{ column.routerNewTab ? '_blank' : undefined }}"> -->
    {{ value }}
  </a>
</ng-template>

<ng-template #hrefClickCell let-value="value" let-column="column" let-row="row">
  <a href="javascript:void(0)" (click)="column.onClick(row, value)" [ngClass]="column.isPrimary ? 'strong' : ''">
    <div (click)="column.onClick(row, value)">
      {{ value }}
    </div>
  </a>
</ng-template>

<ng-template #hrefClickCellCalificar let-value="value" let-column="column" let-row="row">
  <a href="javascript:void(0)" (click)="column.onClick(row, value)" [ngClass]="column.isPrimary ? 'strong' : ''">
    <div (click)="column.onClick(row, value)">
      Calificar
    </div>
  </a>
</ng-template>

<ng-template #iconActionsCell let-column="column" let-row="row">
  <span *ngFor="let action of column.actions">
    <i class="fa action-icon" [ngClass]="action.class" (click)="action.onClick(row)" *ngIf="!action.visible || action.visible(row)"></i>
  </span>
</ng-template>
