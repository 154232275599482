var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ReportCardsService, } from '../../services/report-cards.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService, ViewService, ConfirmationService, DivisionService, TeacherService, SubjectService } from '../../services';
var SubjectReportCardsComponent = /** @class */ (function () {
    function SubjectReportCardsComponent(viewService, divisionService, reportCardsService, teacherService, subjectService, route, authService, confirmationModal) {
        var _this = this;
        this.viewService = viewService;
        this.divisionService = divisionService;
        this.reportCardsService = reportCardsService;
        this.teacherService = teacherService;
        this.subjectService = subjectService;
        this.route = route;
        this.authService = authService;
        this.confirmationModal = confirmationModal;
        this.pageData = {};
        this.isLoading = true;
        this.currentReportCardStageState = null;
        this.currentReportCardStageName = null;
        this.currentReportCardStageIndex = null;
        this.rows = [];
        this.columns = [];
        this.missing = null;
        this.missingSubjects = [];
        this.changes = {};
        this.actions = {};
        this.studentsWithoutReportCard = [];
        this.showMissing = false;
        this.courses = [];
        this.noReportCardsError = false;
        this.noSubjectError = false;
        this.goBack = function () { return _this.viewService.goBack(); };
        this.isDirty = function () { return false; };
        this.route.data
            .subscribe(function (pageData) {
            _this.pageData = pageData;
        });
    }
    SubjectReportCardsComponent.prototype.ngOnInit = function () {
        this.fetchRouteParams();
    };
    SubjectReportCardsComponent.prototype.editCell = function (value) {
        var _this = this;
        this.confirmationModal.show("REPORT_CARDS.NEW_VALUE_GRADE", "DIVISION.ADD_VALUE", true, true, { value: value.grade || "", attachments: value.attachments || [], comments: value.comments || "" })
            .subscribe(function (hasData) {
            if (hasData) {
                var payload = _this.confirmationModal.getPayload();
                value.grade = payload.value;
                value.comments = payload.comments;
                value.attachments = payload.attachments;
                if (!_this.changes[value.stageIndex]) {
                    _this.changes[value.stageIndex] = {};
                }
                _this.changes[value.stageIndex][value.reportCardId] = payload;
                _this.updateActions();
            }
            _this.confirmationModal.clearInputValue();
        });
    };
    SubjectReportCardsComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promises, reportCardId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = [];
                        for (reportCardId in this.actions) {
                            promises.push(this.reportCardsService.applyActions(reportCardId, [this.actions[reportCardId]]));
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        this.actions = {};
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubjectReportCardsComponent.prototype.cancel = function () {
        this.goBack();
    };
    SubjectReportCardsComponent.prototype.openPreview = function () {
        window.open(this.reportCardsService.baseUrl + '/report-cards/preview/' + this.reportCard._id, "_blank");
    };
    SubjectReportCardsComponent.prototype.approve = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (((_a = this.missing) === null || _a === void 0 ? void 0 : _a.size) !== 0) {
                            this.showMissing = true;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.all(this.reportCards.map(function (reportCard) { return __awaiter(_this, void 0, void 0, function () {
                                var e_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, this.reportCardsService.applyActions(reportCard._id, [
                                                    {
                                                        payload: {
                                                            stageIndex: "" + this.currentReportCardStageIndex,
                                                        },
                                                        type: 'Approve'
                                                    }
                                                ])];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 3];
                                        case 2:
                                            e_1 = _a.sent();
                                            console.error(e_1);
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 1:
                        _b.sent();
                        this.actions = {};
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubjectReportCardsComponent.prototype.actionsLength = function () {
        return Object.keys(this.actions).length;
    };
    SubjectReportCardsComponent.prototype.publish = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(this.reportCards.map(function (reportCard) { return __awaiter(_this, void 0, void 0, function () {
                            var e_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.reportCardsService.applyActions(reportCard._id, [
                                                {
                                                    payload: {
                                                        stageIndex: "" + this.currentReportCardStageIndex,
                                                    },
                                                    type: 'PublishTerm'
                                                }
                                            ])];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_2 = _a.sent();
                                        console.error(e_2);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        this.actions = {};
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubjectReportCardsComponent.prototype.updateActions = function () {
        var actions = {};
        for (var stageIndex in this.changes) {
            if (this.changes.hasOwnProperty(stageIndex)) {
                var stageChanges = this.changes[stageIndex];
                for (var reportCardId in stageChanges) {
                    if (stageChanges.hasOwnProperty(reportCardId)) {
                        var payload = stageChanges[reportCardId];
                        actions[reportCardId] = {
                            payload: {
                                grade: payload.value || "",
                                comments: payload.comments || "",
                                attachments: payload.attachments || [],
                                stageIndex: stageIndex,
                                subjectId: this.subject._id,
                                reportCardId: reportCardId
                            },
                            type: 'SetGrade'
                        };
                    }
                }
            }
        }
        this.actions = actions;
        console.log(this, this.actions);
    };
    SubjectReportCardsComponent.prototype.disapprove = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(this.reportCards.map(function (reportCard) { return __awaiter(_this, void 0, void 0, function () {
                            var e_3;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.reportCardsService.applyActions(reportCard._id, [
                                                {
                                                    payload: {
                                                        stageIndex: "" + this.currentReportCardStageIndex,
                                                    },
                                                    type: 'Disapprove'
                                                }
                                            ])];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_3 = _a.sent();
                                        console.error(e_3);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        this.actions = {};
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubjectReportCardsComponent.prototype.createSubject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(this.reportCards.map(function (reportCard) { return __awaiter(_this, void 0, void 0, function () {
                            var e_4;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.reportCardsService.applyActions(reportCard._id, [
                                                {
                                                    payload: {
                                                        subjectId: this.subject._id,
                                                    }, type: 'AddSubject'
                                                }
                                            ])];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_4 = _a.sent();
                                        console.error(e_4);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        this.actions = {};
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubjectReportCardsComponent.prototype.fetchRouteParams = function () {
        var _this = this;
        this.route.params
            .subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
            var currentRole, _a, division, students, courses, subjectReportCards, _b, teachers, subjectQ, subject;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.isLoading = true;
                        currentRole = this.authService.getCurrentUser().currentRole;
                        this.isAdmin = currentRole === "owner" || currentRole === "supervisor";
                        return [4 /*yield*/, Promise.all([
                                promisify(this.divisionService.getById(params.divisionId)),
                                promisify(this.divisionService.getStudents(params.divisionId)),
                                promisify(this.divisionService.getCourses(params.divisionId)),
                                this.reportCardsService.getSubjectReportCardsForDivisionAndCourse(params.divisionId, params.courseId),
                            ])];
                    case 1:
                        _a = _c.sent(), division = _a[0], students = _a[1], courses = _a[2], subjectReportCards = _a[3];
                        this.division = division;
                        this.students = students;
                        this.courses = courses.list;
                        this.course = this.courses.find(function (course) { return course._id === params.courseId; });
                        this.reportCards = subjectReportCards.reportCards;
                        return [4 /*yield*/, Promise.all([
                                this.teacherService.getByIds(this.course.teachers),
                                promisify(this.subjectService.getById(this.course.subject)),
                            ])];
                    case 2:
                        _b = _c.sent(), teachers = _b[0], subjectQ = _b[1];
                        this.teachers = teachers;
                        this.subject = subjectQ;
                        this.reportCard = this.reportCards[0];
                        if (this.reportCards.length === 0) {
                            this.noReportCardsError = true;
                            this.isLoading = false;
                            return [2 /*return*/];
                        }
                        console.log("Subject Report Cards", subjectReportCards);
                        console.log("Report cards", this.reportCards);
                        console.log("Division", this.division);
                        console.log("Students", this.students);
                        console.log("Courses", this.courses);
                        console.log("Course", this.course);
                        console.log("Teachers", this.teachers);
                        console.log("Subject", this.subject);
                        subject = this.reportCard.subjects.find(function (e) { return e.subjectId === _this.subject._id; });
                        if (!!subject) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.createSubject()];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                    case 4:
                        this.columns = __spreadArrays([
                            { name: 'Alumnos', prop: 'studentFullName', sortable: false }
                        ], this.reportCard.stages.map(function (stage, index) { return ({ name: stage.name, prop: 'grade_' + index, sortable: false }); }));
                        this.currentReportCardStageIndex = null;
                        this.rows = __spreadArrays(this.students.map(function (student) {
                            var map = {};
                            var reportCard = _this.reportCards.find(function (e) { return e.studentId === student._id; });
                            if (reportCard) {
                                reportCard.stages.forEach(function (stage, index) {
                                    if ((stage.state === 'Pending' || stage.state === 'Ready' || stage.state === 'Approved') && stage.name !== 'Promedio') {
                                        if (_this.currentReportCardStageIndex === null || index < _this.currentReportCardStageIndex) {
                                            _this.currentReportCardStageIndex = index;
                                            _this.currentReportCardStageName = stage.name;
                                            _this.currentReportCardStageState = stage.state;
                                        }
                                    }
                                    var isAllowed = (stage.state === "Pending" || stage.state === "Ready") && (currentRole === 'owner'
                                        || currentRole === 'supervisor'
                                        || subject.teachers.find(function (teacher) { return teacher.teacherId === _this.authService.getCurrentUser()._id; }) !== undefined);
                                    var grade = stage.data.find(function (s) { return s.subjectId === subject.subjectId; });
                                    map["grade_" + index] = {
                                        stageIndex: index,
                                        subjectId: subject.subjectId,
                                        reportCardId: reportCard._id,
                                        allowed: isAllowed,
                                        grade: grade ? grade.value : null,
                                        // @ts-ignore
                                        comments: (grade === null || grade === void 0 ? void 0 : grade.comments) || "",
                                        // @ts-ignore
                                        attachments: (grade === null || grade === void 0 ? void 0 : grade.attachments) || [],
                                    };
                                });
                            }
                            else {
                                _this.studentsWithoutReportCard.push(student);
                            }
                            return (__assign({ studentFullName: student.name.first + " " + student.name.last }, map));
                        }));
                        if (this.currentReportCardStageIndex != null) {
                            this.missing = this.reportCards.map(function (r) {
                                var studentName = r.studentName.first + r.studentName.last;
                                var state = r.stages[_this.currentReportCardStageIndex].state;
                                var all = r.subjects.map(function (s) { return ({ id: s.subjectId, name: s.subjectName }); });
                                var graded = r.stages[_this.currentReportCardStageIndex].data.map(function (g) { return g.subjectId; });
                                var missing = all.filter(function (subject) { return !graded.find(function (g) { return g === subject.id; }); }).map(function (e) { return e.name; });
                                return { studentName: studentName, state: state, missing: missing };
                            }).map(function (e) { return e.missing; }).reduce(function (set, subjectNames) {
                                for (var _i = 0, subjectNames_1 = subjectNames; _i < subjectNames_1.length; _i++) {
                                    var subjectName = subjectNames_1[_i];
                                    set.add(subjectName);
                                }
                                return set;
                            }, new Set());
                            this.missingSubjects = this.reportCard.subjects.filter(function (s) { var _a; return (_a = _this.missing) === null || _a === void 0 ? void 0 : _a.has(s.subjectName); }).map(function (s) {
                                var _a;
                                return ({
                                    name: s.subjectName,
                                    courseId: ((_a = _this.courses.find(function (c) { return c.subject === s.subjectId; })) === null || _a === void 0 ? void 0 : _a._id) || ""
                                });
                            });
                            console.log(this.missing);
                            console.log("Missing Subjects", this.missingSubjects);
                        }
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return SubjectReportCardsComponent;
}());
export { SubjectReportCardsComponent };
function promisify(observable) {
    return new Promise(function (resolve, reject) {
        observable.subscribe(resolve, reject);
    });
}
