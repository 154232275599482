var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ReportCardsService } from '../../services/report-cards.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService, ViewService, ConfirmationService } from '../../services';
var ReportCardComponent = /** @class */ (function () {
    function ReportCardComponent(viewService, reportCardsService, route, authService, confirmationModal) {
        var _this = this;
        this.viewService = viewService;
        this.reportCardsService = reportCardsService;
        this.route = route;
        this.authService = authService;
        this.confirmationModal = confirmationModal;
        this.pageData = {};
        this.isLoading = true;
        this.currentReportCardStage = null;
        this.rows = [];
        this.columns = [];
        this.changes = {};
        this.actions = [];
        this.goBack = function () { return _this.viewService.goBack(); };
        this.isDirty = function () { return false; };
        this.route.data
            .subscribe(function (pageData) {
            _this.pageData = pageData;
        });
    }
    ReportCardComponent.prototype.ngOnInit = function () {
        this.fetchRouteParams();
    };
    ReportCardComponent.prototype.editCell = function (value) {
        var _this = this;
        this.confirmationModal.show("REPORT_CARDS.NEW_VALUE_GRADE", "DIVISION.ADD_VALUE", true, true, { value: value.grade || "", attachments: value.attachments || [], comments: value.comments || "" })
            .subscribe(function (hasData) {
            if (hasData) {
                var payload = _this.confirmationModal.getPayload();
                value.grade = payload.value;
                value.comments = payload.comments;
                value.attachments = payload.attachments;
                if (!_this.changes[value.stageIndex]) {
                    _this.changes[value.stageIndex] = {};
                }
                _this.changes[value.stageIndex][value.subjectId] = payload;
                _this.updateActions();
            }
            _this.confirmationModal.clearInputValue();
        });
    };
    ReportCardComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportCardsService.applyActions(this.reportCard._id, this.actions)];
                    case 1:
                        _a.sent();
                        this.actions = [];
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportCardComponent.prototype.cancel = function () {
        this.goBack();
    };
    ReportCardComponent.prototype.openPreview = function () {
        window.open(this.reportCardsService.baseUrl + '/report-cards/preview/' + this.reportCard._id, "_blank");
    };
    ReportCardComponent.prototype.approve = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportCardsService.applyActions(this.reportCard._id, [
                            {
                                payload: {
                                    stageIndex: "" + this.reportCard.stages.indexOf(this.currentReportCardStage),
                                },
                                type: 'Approve'
                            }
                        ])];
                    case 1:
                        _a.sent();
                        this.actions = [];
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportCardComponent.prototype.publish = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportCardsService.applyActions(this.reportCard._id, [
                            {
                                payload: {
                                    stageIndex: "" + this.reportCard.stages.indexOf(this.currentReportCardStage),
                                },
                                type: 'PublishTerm'
                            }
                        ])];
                    case 1:
                        _a.sent();
                        this.actions = [];
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportCardComponent.prototype.updateActions = function () {
        var actions = [];
        for (var stageIndex in this.changes) {
            if (this.changes.hasOwnProperty(stageIndex)) {
                var stageChanges = this.changes[stageIndex];
                for (var subjectId in stageChanges) {
                    if (stageChanges.hasOwnProperty(subjectId)) {
                        var payload = stageChanges[subjectId];
                        actions.push({
                            payload: {
                                grade: payload.value || "",
                                comments: payload.comments || "",
                                attachments: payload.attachments || [],
                                stageIndex: stageIndex,
                                subjectId: subjectId
                            },
                            type: 'SetGrade'
                        });
                    }
                }
            }
        }
        this.actions = actions;
    };
    ReportCardComponent.prototype.disapprove = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportCardsService.applyActions(this.reportCard._id, [
                            {
                                payload: {
                                    stageIndex: "" + this.reportCard.stages.indexOf(this.currentReportCardStage),
                                },
                                type: 'Disapprove'
                            }
                        ])];
                    case 1:
                        _a.sent();
                        this.actions = [];
                        this.changes = {};
                        this.fetchRouteParams();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportCardComponent.prototype.fetchRouteParams = function () {
        var _this = this;
        this.route.params
            .subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
            var currentRole, reportCardId, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        currentRole = this.authService.getCurrentUser().currentRole;
                        this.isAdmin = currentRole === "owner" || currentRole === "supervisor";
                        reportCardId = params['id'];
                        _a = this;
                        return [4 /*yield*/, this.reportCardsService.getReportCardById(reportCardId).toPromise()];
                    case 1:
                        _a.reportCard = _b.sent();
                        this.columns = __spreadArrays([
                            { name: 'Asignaturas', prop: 'subject', sortable: false }
                        ], this.reportCard.stages.map(function (stage, index) { return ({ name: stage.name, prop: 'grade_' + index, sortable: false }); }));
                        this.rows = __spreadArrays(this.reportCard.subjects.map(function (subject) {
                            var map = {};
                            _this.reportCard.stages.forEach(function (stage, index) {
                                if (stage.state === 'Pending' || stage.state === 'Ready' || stage.state === 'Approved') {
                                    _this.currentReportCardStage = stage;
                                }
                                else {
                                    //  this.currentReportCardStage = null
                                }
                                if (stage.state === 'Approved' && stage.name == 'Promedio') {
                                    _this.currentReportCardStage = null;
                                }
                                var isAllowed = (stage.state === "Pending" || stage.state === "Ready") && (currentRole === 'owner'
                                    || currentRole === 'supervisor'
                                    || subject.teachers.find(function (teacher) { return teacher.teacherId === _this.authService.getCurrentUser()._id; }) !== undefined);
                                var grade = stage.data.find(function (s) { return s.subjectId === subject.subjectId; });
                                map["grade_" + index] = {
                                    stageIndex: index,
                                    subjectId: subject.subjectId,
                                    allowed: isAllowed,
                                    grade: grade ? grade.value : null,
                                    // @ts-ignore
                                    comments: (grade === null || grade === void 0 ? void 0 : grade.comments) || "",
                                    // @ts-ignore
                                    attachments: (grade === null || grade === void 0 ? void 0 : grade.attachments) || [],
                                };
                            });
                            return (__assign({ subject: subject.subjectName }, map));
                        }));
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return ReportCardComponent;
}());
export { ReportCardComponent };
