import { Observable } from 'rxjs/Observable'
import { Column, QueryString } from '../../models'
import { ViewService } from '../../services'
import { SearchPanel } from '../../interfaces'

export abstract class StudentsTab extends SearchPanel<any> {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'columns' has no initializer and is not d... Remove this comment to see the full error message
  public columns: Column[]

  constructor(
    viewService: ViewService
  ) {
    super(viewService, 'user')
    this.params.limit = 50
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }
  }

  public activate() {
    this.createColumns()
    this.updateFilter()
  }

  public onSelect(selected: any) {
    return this.viewService.goToDetail('students', 'student', selected)
  }

  protected abstract fetch(params: QueryString): Observable<any>

  protected createColumns(): void {
    const width = 60;

    const shiftType = this.viewService.getSelectedStage().shiftType
    const isLate2Enabled = this.viewService.getSelectedStage().late2Enabled

    this.columns = [
      Column.forClick('_id', 'USER.ID', this.onSelect.bind(this)),
      Column.forClick('name.last', 'USER.LAST_NAME', this.onSelect.bind(this)),
      Column.forClick('name.first', 'USER.FIRST_NAME', this.onSelect.bind(this)),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.late', this.formatFirstShiftTitle('Tarde', shiftType), false, null, 'warningBadgeCell', width, width, 'centeredColHeader'),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      ...isLate2Enabled ? [new Column('membership.stats.late2', this.formatFirstShiftTitle('Tarde 2', shiftType), false, null, 'warningBadgeCell', width, width, 'centeredColHeader')] : [],

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.absence', this.formatFirstShiftTitle('Ausente', shiftType), false, null, 'dangerBadgeCell', width, width, 'centeredColHeader'),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.justified', this.formatFirstShiftTitle('Justif.', shiftType), false, null, 'infoBadgeCell', width, width, 'centeredColHeader'),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.early_pick_up', this.formatFirstShiftTitle('Ret. Ant.', shiftType), false, null, 'infoBadgeCell', width, width, 'centeredColHeader'),

      ...shiftType === 'double' ? [
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        new Column('membership.stats.second_late', '2 Tarde', false, null, 'warningBadgeCell', width, width, 'centeredColHeader'),

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        ...isLate2Enabled ? [new Column('membership.stats.second_late2', '2 Tarde 2', false, null, 'warningBadgeCell', width, width, 'centeredColHeader')] : [],

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        new Column('membership.stats.second_absence', '2 Ausente', false, null, 'dangerBadgeCell', width, width, 'centeredColHeader'),

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        new Column('membership.stats.second_justified', '2 Justif.', false, null, 'infoBadgeCell', width, width, 'centeredColHeader'),

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        new Column('membership.stats.second_early_pick_up', '2 Ret. Ant.', false, null, 'infoBadgeCell', width, width, 'centeredColHeader')] : [],

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 70, 70),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
    ]
  }

  formatFirstShiftTitle(title: string, shiftType: 'simple' | 'double' | undefined): string {
    return `${shiftType === 'double' ? '1 ' : ''}${title}`;
  }
}
