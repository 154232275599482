import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { TranslatePipe } from '@ngx-translate/core'
import * as moment from 'moment'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { DivisionService, ViewService, TermService } from '../../services'
import { QueryString } from '../../models'

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.styl'],
  providers: [TranslatePipe]
})

export class AttendanceComponent implements OnInit {
  public pageData: any = {}

  public isSaveAllowed: boolean
  public isLoading: boolean = false


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isSubmitting' has no initializer and is ... Remove this comment to see the full error message
  public isSubmitting: boolean

  public structure: any[] = []


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'stageIdx' has no initializer and is not ... Remove this comment to see the full error message
  public stageIdx: number


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'gradeIdx' has no initializer and is not ... Remove this comment to see the full error message
  public gradeIdx: number


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionIdx' has no initializer and is n... Remove this comment to see the full error message
  public divisionIdx: number
  public stage: any
  public grade: any
  public division: any

  public students: any[] = []


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'form' has no initializer and is not defi... Remove this comment to see the full error message
  public form: FormGroup

  public locale: string
  public days: any[] = []


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'today' has no initializer and is not def... Remove this comment to see the full error message
  public today: number


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'selected' has no initializer and is not ... Remove this comment to see the full error message
  public selected: number
  public selectedDate: any // used byt datepicker

  private isoWeek: any
  private weekSheets: any = {}

  public shiftType: 'simple' | 'double' | undefined = undefined
  public firstShiftName: string | undefined = undefined
  public secondShiftName: string | undefined = undefined
  public late2Enabled = false

  constructor (
    private service: DivisionService,
    private viewService: ViewService,
    private route: ActivatedRoute,
    private termService: TermService,
    private fb: FormBuilder
  ) {
    this.route.data.subscribe(pageData => { this.pageData = pageData })
    this.locale = viewService.getCurrentUser().locale
    this.isSaveAllowed = this.viewService.isAllowed('division', 'attendance')
    this.late2Enabled = this.viewService.getSelectedStage().late2Enabled || false
  }

  public ngOnInit () {
    this.route.params
    .subscribe(params => {
      this.termService.getCurrentStructure()
      .subscribe(
        structure => {
          // reset
          this.structure = structure || []
          this.stage = null
          this.grade = null
          this.division = null
          this.gradeIdx = -1
          this.divisionIdx = -1


          // @ts-expect-error ts-migrate(6133) FIXME: 'stageId' is declared but its value is never read.
          const stageId = params.stageId !== 'undefined' ? undefined : params.stageId


          // @ts-expect-error ts-migrate(6133) FIXME: 'gradeId' is declared but its value is never read.
          const gradeId = params.gradeId !== 'undefined' ? undefined : params.gradeId


          // @ts-expect-error ts-migrate(6133) FIXME: 'divisionId' is declared but its value is never re... Remove this comment to see the full error message
          const divisionId = params.divisionId !== 'undefined' ? undefined : params.divisionId



          this.stageIdx = this.structure.findIndex(stage => stage._id === params.stageId)
          if (this.stageIdx >= 0) {
            this.stage = this.structure[this.stageIdx]
            this.shiftType = this.stage['shiftType']
            this.firstShiftName = this.stage['firstShiftName']
            this.secondShiftName = this.stage['secondShiftName']

            this.gradeIdx = this.stage.grades.findIndex((grade: any) => grade._id === params.gradeId)
            if (this.gradeIdx >= 0) {
              this.grade = this.stage.grades[this.gradeIdx]


              this.divisionIdx = this.grade.divisions.findIndex((division: any) => division._id === params.divisionId)
              if (this.divisionIdx >= 0) {
                this.division = this.grade.divisions[this.divisionIdx]
              }
            }
          }

          this.changeSelectedWeek() // no params for today
          this.loadStudents()
        },
        this.viewService.handleError
      )
    })
  }

  public changeSelectedWeek (initial?: number) {
    // if day is within current week, do not change
    const days = this.days
    initial = moment(initial).startOf('day').valueOf()
    if (days.length && initial >= days[0] && initial <= days[6]) {
      this.changeSelectedDate(initial)
      return false
    }

    // gnerate week information
    this.days = []
    this.isoWeek = moment(initial).startOf('isoWeek')
    const weekDay = moment(this.isoWeek)
    for (let i = 0; i <= 6; i++) {
      this.days[i] = weekDay.valueOf()
      weekDay.add(1, 'day')
    }


    this.today = moment().startOf('day').valueOf()
    this.selected = this.preSelectedDay(initial)
    this.selectedDate = new Date(this.selected)
    return true
  }

  public changeSelectedDate (selected: any) {
    if (selected > this.today || this.selected === selected || this.isSubmitting || this.isLoading) {
      return
    }
    const _changeSelectedDate = () => {
      this.selected = selected
      this.selectedDate = new Date(this.selected)
      this.form.reset()
      this.form.patchValue({
        'first': this.weekSheets['first'][this.selected],
        'first_alt': this.weekSheets['first_alt'][this.selected],
        'second': this.weekSheets['second'][this.selected],
        'second_alt': this.weekSheets['second_alt'][this.selected],
      })
      this.form.markAsPristine()
    }

    if (!this.form.dirty) {
      _changeSelectedDate()
      return
    }

    this.viewService.askConfirmation('DIVISION.ATTENDANCE_DISCARD_CHANGES')
    .subscribe(result => {
      if (result) {
        _changeSelectedDate()
      }
    })
  }

  public onEarlyPickUpClick(shift: 'first_alt' | 'second_alt', studentId: string) {
    let form = this.form.get(shift) as FormGroup
    let control = form.get(studentId)!
    let value = control.value || biz.TOPIC_TYPES.PRESENCE
    control.setValue(value === biz.TOPIC_TYPES.PRESENCE ? 'early_pick_up' : biz.TOPIC_TYPES.PRESENCE)
  }

  public submit () {
    this.isSubmitting = true
    const attendanceSheet = this.createAttendanceSheet()

    this.service.saveAttendance(this.division._id, this.selected, attendanceSheet)
    .finally(() => { this.isSubmitting = false })
    .subscribe(
      () => {
        this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
        this.weekSheets[this.selected] = this.form.value
        this.form.markAsPristine()
      },
      this.viewService.handleError
    )
  }

  public onSelectedDateChange () {
    if (this.changeSelectedWeek(this.selectedDate)) {
      this.loadWeekSheets()
    }
  }

  public onStageChange () {
    this.stage = this.structure[this.stageIdx]
    if (this.stage && this.stage.grades && this.stage.grades.length) {
      this.gradeIdx = 0
    } else {
      this.gradeIdx = -1
    }
    this.onGradeChange()
  }

  public onGradeChange () {
    this.grade = this.stage.grades[this.gradeIdx]
    if (this.grade && this.grade.divisions && this.grade.divisions.length) {
      this.divisionIdx = 0
    } else {
      this.divisionIdx = -1
    }
    this.onDivisionChange()
  }

  public onDivisionChange () {
    this.division = this.grade.divisions[this.divisionIdx]
    const stagePart = this.stage ? this.stage._id : 'undefined'
    const gradePart = this.grade ? this.grade._id : 'undefined'
    const divisionPart = this.division ? this.division._id : 'undefined'
    this.viewService.goTo([`../../../${stagePart}/${gradePart}/${divisionPart}`], { relativeTo: this.route, replaceUrl: true })
  }

  public goBack () {
    return this.viewService.goBack()
  }

  public isDirty (): boolean {
    return this.form && this.form.dirty
  }

  private preSelectedDay (date: any): number {
    date = moment(date)
    // http://momentjs.com/docs/#/get-set/day/
    if (date.day() === 0) {
      // sunday -> friday
      return date.add(-2, 'day').valueOf()
    } else if (date.day() === 6) {
      // saturday -> friday
      return date.add(-1, 'day').valueOf()
    }
    return date.valueOf()
  }

  private loadStudents () {
    this.students = []
    if (this.divisionIdx < 0) {
      return
    }

    this.isLoading = true
    this.form = this.fb.group({
      first: this.fb.group({}),
      first_alt: this.fb.group({}),
      second: this.fb.group({}),
      second_alt: this.fb.group({}),
    });
    const options = new QueryString({
      sort: {
        'name.last': 1,
        'name.first': 1
      }
    })

    this.service.getStudents(this.division._id, options)
    .subscribe(
      students => {
        this.students = students || []
        this.loadWeekSheets()
        // const sheet = this.weekSheets[this.selected] || {}
        this.students.forEach(student => {
          // sheet[student._id] || biz.TOPIC_TYPES.PRESENCE
          (this.form!.get('first') as FormGroup)!.addControl(student._id, new FormControl( biz.TOPIC_TYPES.PRESENCE , Validators.required));
          (this.form!.get('first_alt') as FormGroup)!.addControl(student._id, new FormControl( biz.TOPIC_TYPES.PRESENCE , Validators.required));
          (this.form!.get('second') as FormGroup)!.addControl(student._id, new FormControl( biz.TOPIC_TYPES.PRESENCE , Validators.required));
          (this.form!.get('second_alt') as FormGroup)!.addControl(student._id, new FormControl( biz.TOPIC_TYPES.PRESENCE , Validators.required));
        })
      },
      err => {
        this.viewService.handleError(err)
        this.isLoading = false
      }
    )
  }

  private loadWeekSheets () {
    this.weekSheets = {}
    if (!this.division) {
      return
    }

    this.isLoading = true
    const week = moment(this.selected)

    this.service.getAttendanceByWeek(this.division._id, week.year(), week.isoWeek())
    .finally(() => { this.isLoading = false })
    .subscribe(
      weekSheets => {
        this.form.reset()
        this.weekSheets = {
          'first': {},
          'first_alt': {},
          'second': {},
          'second_alt': {},
        }

        // map server format to client
        this.days.forEach(day => {
          const dateValue = this.toDateValue(day)


          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const sheet = this.weekSheets['first'][day] = weekSheets['first'][dateValue] || {}

          // if there is no event the student was present, so fill the gaps
          this.students.forEach(student => {
            sheet[student._id] = sheet[student._id] || biz.TOPIC_TYPES.PRESENCE
            // assign sheet values to the form for the selected day
            if (day === this.selected) {
              const firstShiftFormGroup = this.form.get('first') as FormGroup;
              const control = firstShiftFormGroup.get(student._id);
              if (control) {
                control.setValue(sheet[student._id])
              }
            }
          })
        })

        // map server format to client
        this.days.forEach(day => {
          const dateValue = this.toDateValue(day)

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const sheet = this.weekSheets['first_alt'][day] = weekSheets['first_alt'][dateValue] || {}

          // if there is no event the student was present, so fill the gaps
          this.students.forEach(student => {
            sheet[student._id] = sheet[student._id] || biz.TOPIC_TYPES.PRESENCE
            // assign sheet values to the form for the selected day
            if (day === this.selected) {
              const firstShiftFormGroup = this.form.get('first_alt') as FormGroup;
              const control = firstShiftFormGroup.get(student._id);
              if (control) {
                control.setValue(sheet[student._id])
              }
            }
          })
        })

        // map server format to client
        this.days.forEach(day => {
          const dateValue = this.toDateValue(day)


          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const sheet = this.weekSheets['second'][day] = weekSheets['second'][dateValue] || {}

          // if there is no event the student was present, so fill the gaps
          this.students.forEach(student => {
            sheet[student._id] = sheet[student._id] || biz.TOPIC_TYPES.PRESENCE
            // assign sheet values to the form for the selected day
            if (day === this.selected) {
              const firstShiftFormGroup = this.form.get('second') as FormGroup;
              const control = firstShiftFormGroup.get(student._id);
              if (control) {
                control.setValue(sheet[student._id])
              }
            }
          })
        })

        // map server format to client
        this.days.forEach(day => {
          const dateValue = this.toDateValue(day)

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const sheet = this.weekSheets['second_alt'][day] = weekSheets['second_alt'][dateValue] || {}

          // if there is no event the student was present, so fill the gaps
          this.students.forEach(student => {
            sheet[student._id] = sheet[student._id] || biz.TOPIC_TYPES.PRESENCE
            // assign sheet values to the form for the selected day
            if (day === this.selected) {
              const firstShiftFormGroup = this.form.get('second_alt') as FormGroup;
              const control = firstShiftFormGroup.get(student._id);
              if (control) {
                control.setValue(sheet[student._id])
              }
            }
          })
        })

        // mark form as printine so no it's no dirty
        this.form.markAsPristine()
      },
      this.viewService.handleError
    )
  }

  private createAttendanceSheet () {
    const value = this.form.value
    const first = value.first
    const first_alt = value.first_alt
    const second = value.second
    const second_alt = value.second_alt

    const sheet = {
      'first': {},
      'first_alt': {},
      'second': {},
      'second_alt': {},
    }

    Object.keys(first).forEach(_id => {
      if (first[_id] !== biz.TOPIC_TYPES.PRESENCE) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        sheet['first'][_id] = first[_id]
      }
    })
    Object.keys(first_alt).forEach(_id => {
      if(first_alt[_id] !== biz.TOPIC_TYPES.PRESENCE){
        // @ts-expect-error
        sheet['first_alt'][_id] = first_alt[_id]
      }
    })
    Object.keys(second).forEach(_id => {
      if (second[_id] !== biz.TOPIC_TYPES.PRESENCE) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        sheet['second'][_id] = second[_id]
      }
    })

    Object.keys(second_alt).forEach(_id => {
      if(second_alt[_id] !== biz.TOPIC_TYPES.PRESENCE){
        // @ts-expect-error
        sheet['second_alt'][_id] = second_alt[_id]
      }
    })
    return sheet
  }

  private toDateValue (day: number) {
    const d = moment(day)
    return (d.year() * 100000000) + (d.month() * 1000000) + (d.date() * 10000)
  }
}
