
import * as _ from 'lodash-es'
import { base } from './base'

export const environment = _.extend(base, {
  production: true,
  API_HOST: 'api.nexedu.com.ar',
  API_URL: 'https://api.nexedu.com.ar',
  CLOUDINARY: {
    CLOUD_NAME : 'nexedu-prod2',
    TENANTS_PRESET: 'tenants',
    IMPORTS_PRESET: 'imports',
    ATTACHMENTS_PRESET: 'attachments',
    ALBUMS_PRESET: 'albums'
  },
  KEEN: {
    PROJECT_ID: '',
    KEY: ''
  }
})
