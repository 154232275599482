var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Column } from '../../models';
import { SearchPanel } from '../../interfaces';
var StudentsTab = /** @class */ (function (_super) {
    __extends(StudentsTab, _super);
    function StudentsTab(viewService) {
        var _this = _super.call(this, viewService, 'user') || this;
        _this.params.limit = 50;
        _this.params.sort = {
            'name.last': 1,
            'name.first': 1
        };
        return _this;
    }
    StudentsTab.prototype.activate = function () {
        this.createColumns();
        this.updateFilter();
    };
    StudentsTab.prototype.onSelect = function (selected) {
        return this.viewService.goToDetail('students', 'student', selected);
    };
    StudentsTab.prototype.createColumns = function () {
        var width = 60;
        var shiftType = this.viewService.getSelectedStage().shiftType;
        var isLate2Enabled = this.viewService.getSelectedStage().late2Enabled;
        this.columns = __spreadArrays([
            Column.forClick('_id', 'USER.ID', this.onSelect.bind(this)),
            Column.forClick('name.last', 'USER.LAST_NAME', this.onSelect.bind(this)),
            Column.forClick('name.first', 'USER.FIRST_NAME', this.onSelect.bind(this)),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.late', this.formatFirstShiftTitle('Tarde', shiftType), false, null, 'warningBadgeCell', width, width, 'centeredColHeader')
        ], isLate2Enabled ? [new Column('membership.stats.late2', this.formatFirstShiftTitle('Tarde 2', shiftType), false, null, 'warningBadgeCell', width, width, 'centeredColHeader')] : [], [
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.absence', this.formatFirstShiftTitle('Ausente', shiftType), false, null, 'dangerBadgeCell', width, width, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.justified', this.formatFirstShiftTitle('Justif.', shiftType), false, null, 'infoBadgeCell', width, width, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.early_pick_up', this.formatFirstShiftTitle('Ret. Ant.', shiftType), false, null, 'infoBadgeCell', width, width, 'centeredColHeader')
        ], shiftType === 'double' ? __spreadArrays([
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.second_late', '2 Tarde', false, null, 'warningBadgeCell', width, width, 'centeredColHeader')
        ], isLate2Enabled ? [new Column('membership.stats.second_late2', '2 Tarde 2', false, null, 'warningBadgeCell', width, width, 'centeredColHeader')] : [], [
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.second_absence', '2 Ausente', false, null, 'dangerBadgeCell', width, width, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.second_justified', '2 Justif.', false, null, 'infoBadgeCell', width, width, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.second_early_pick_up', '2 Ret. Ant.', false, null, 'infoBadgeCell', width, width, 'centeredColHeader')
        ]) : [], [
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 70, 70),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
        ]);
    };
    StudentsTab.prototype.formatFirstShiftTitle = function (title, shiftType) {
        return "" + (shiftType === 'double' ? '1 ' : '') + title;
    };
    return StudentsTab;
}(SearchPanel));
export { StudentsTab };
