// EXTERNAL MODULES
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt'
import { AlertModule, BsDatepickerModule, ModalModule, TypeaheadModule, PopoverModule, TimepickerModule, AccordionModule } from 'ngx-bootstrap'
import { ProgressbarModule, TabsModule, TooltipModule, CollapseModule, ButtonsModule, PaginationModule, BsDropdownModule } from 'ngx-bootstrap'
import { ToasterModule } from 'angular2-toaster'
import { environment } from '../environments/environment'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { AppRoutes } from './app.routes'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TagInputModule } from 'ngx-chips'
import { FileUploadModule } from 'ng2-file-upload'
import { LaddaModule } from 'angular2-ladda'
import { MomentModule } from 'angular2-moment'
import { NgPipesModule } from 'ngx-pipes'
import { Ng2CacheModule } from 'ng2-cache'

import { registerLocaleData } from '@angular/common'
import localeEn from '@angular/common/locales/en'
import localeEs from '@angular/common/locales/es'
registerLocaleData(localeEn)
registerLocaleData(localeEs)

// AoT requires an exported function for factories
import { StoreService } from './services'
export function JwtOptionsFactory (storeService: StoreService) {
  return {
    tokenGetter: () => {
      return storeService.getItem('token')
    },
    whitelistedDomains: [ environment.API_HOST ],
    blacklistedRoutes: [ /\/auth\//, /^assets\// ]
  };
}
export function HttpLoaderFactory (http: HttpClient) {
  return new TranslateHttpLoader(http, '/public/assets/i18n/', '.json')
}

const modules = [
  Ng2CacheModule,
  HttpClientModule,
  BrowserModule,
  BrowserAnimationsModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  JwtModule.forRoot({
    jwtOptionsProvider: {
      provide: JWT_OPTIONS,
      useFactory: JwtOptionsFactory,
      deps: [ StoreService ]
    }
  }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [ HttpClient ]
    }
  }),
  AppRoutes,
  ToasterModule,
  NgxDatatableModule,
  TagInputModule,
  TypeaheadModule,
  AlertModule.forRoot(),
  BsDatepickerModule.forRoot(),
  ModalModule.forRoot(),
  ButtonsModule.forRoot(),
  PopoverModule.forRoot(),
  ProgressbarModule.forRoot(),
  TabsModule.forRoot(),
  TooltipModule.forRoot(),
  CollapseModule.forRoot(),
  PaginationModule.forRoot(),
  BsDropdownModule.forRoot(),
  TimepickerModule.forRoot(),
  AccordionModule.forRoot(),
  FileUploadModule,
  LaddaModule.forRoot({
    style: 'zoom-in',
    spinnerLines: 12
  }),
  MomentModule,
  NgPipesModule,
]

// WIDGETS
import { Debounce } from './directives/debounce.directive'
import { AppComponent } from './app.component'

import { AppDataTableComponent } from './widgets/app-datatable'
import { AppHeaderComponent } from './widgets/app-header'
import { AppFooterComponent } from './widgets/app-footer'
import { AppToastrComponent } from './widgets/app-toastr'
import { BreadcrumbComponent } from './widgets/breadcrumb'
import { ConfirmationModal } from './widgets/confirmation-modal'
import { LanguageDropdown } from './widgets/language-dropdown'
import { MenuAsideComponent } from './widgets/menu-aside'
import { MessagesBoxComponent } from './widgets/messages-box'
import { NotificationBoxComponent } from './widgets/notification-box'
import { TasksBoxComponent } from './widgets/tasks-box'
import { Avatar } from './widgets/avatar'
import { UploadFileComponent } from './widgets/upload-file'
import { UploadImageComponent } from './widgets/upload-image'
import { UserAvatar } from './widgets/user-avatar'
import { UserBoxComponent } from './widgets/user-box'
import { StagePickerComponent } from './widgets/stage-picker'
import { TermPickerComponent } from './widgets/term-picker'
import { DatepickerPopup, DateOnlyPickerPopup } from './widgets/datepicker-popup'
import { BatchJobErrorsTab } from './widgets/batch-job-errors-tab'
import { BatchJobErrorPopover } from './widgets/batch-job-error-popover'
import { BatchJobFileModal } from './widgets/batch-job-file-modal'
import { ReportBoxComponent } from './widgets/report-box'
import { HelpIcon, RequiredIcon } from './widgets/label-icons'
import { TagsInput } from './widgets/tags-input'
// custom per app
import { UserPanel } from './widgets/user-panel'
import { StudentGuardiansTab } from './widgets/student-guardians-tab'
import { StudentDivisionsTab } from './widgets/student-divisions-tab'
import { StudentAttendanceTab } from './widgets/student-attendance-tab'
import { DivisionStudentsTab } from './widgets/division-students-tab'
import { DivisionCoursesTab } from './widgets/division-courses-tab'
import { DivisionRatingsTab } from './widgets/division-ratings-tab'
import { DivisionExamsTab } from './widgets/division-exams-tab'
import { GuardianStudentsTab } from './widgets/guardian-students-tab'
import { TopicRecipientsTab } from './widgets/topic-recipients-tab'
import { TopicActionBadge } from './widgets/topic-action-badge'
import { TimeOnlyPicker } from './widgets/time-only-picker'
import { CoursePanel } from './widgets/course-panel'
import { TopicPanel } from './widgets/topic-panel'
import { RecipientsSelector } from './widgets/recipients-selector'
import { RichEditor } from './widgets/rich-editor'
import { FeedPost } from './widgets/feed-post'
import { ExamAssessments } from './widgets/exam-assessments'
import { RatingAssessments } from './widgets/rating-assessments'

const widgets = [
  Debounce,
  Avatar,
  AppComponent,
  AppDataTableComponent,
  AppHeaderComponent,
  AppFooterComponent,
  AppToastrComponent,
  BreadcrumbComponent,
  ConfirmationModal,
  LanguageDropdown,
  MenuAsideComponent,
  MessagesBoxComponent,
  NotificationBoxComponent,
  TasksBoxComponent,
  UploadFileComponent,
  UploadImageComponent,
  UserAvatar,
  UserBoxComponent,
  StagePickerComponent,
  TermPickerComponent,
  DatepickerPopup,
  DateOnlyPickerPopup,
  BatchJobErrorsTab,
  BatchJobErrorPopover,
  BatchJobFileModal,
  ReportBoxComponent,
  HelpIcon,
  RequiredIcon,
  TagsInput,
  // custom per app
  UserPanel,
  StudentGuardiansTab,
  StudentDivisionsTab,
  StudentAttendanceTab,
  StudentReportCardsTab,
  TimeOnlyPicker,
  DivisionStudentsTab,
  DivisionCoursesTab,
  DivisionRatingsTab,
  DivisionExamsTab,
  GuardianStudentsTab,
  TopicRecipientsTab,
  TopicActionBadge,
  CoursePanel,
  TopicPanel,
  RecipientsSelector,
  RichEditor,
  FeedPost,
  ExamAssessments,
  RatingAssessments,
  RecipientsListModal
]

// GUARDS
import { IsUserAllowedGuard, IsUserAlreadyLoggedGuard, IsUserLoggedGuard, IsFormDirtyGuard } from './guards'

const guards = [
  IsUserAllowedGuard,
  IsUserAlreadyLoggedGuard,
  IsUserLoggedGuard,
  IsFormDirtyGuard
  // custom per app
]

// SERVICES
import { BreadcrumbService, MessagesService, PrettySizeService } from './services'
import { AppCacheService, AppToastrService, AppTranslateService } from './services'
import { ConfirmationService, ViewService } from './services'
import { AuthService, MeService, UserService, PersonService, TenantService } from './services'
import { BatchJobService } from './services'
// custom per app
import { StudentService } from './services'
import { GuardianService } from './services'
import { TeacherService } from './services'
import { SupervisorService } from './services'
import { EmployeeService } from './services'
import { TermService } from './services'
import { StageService } from './services'
import { SubjectService } from './services'
import { DivisionService } from './services'
import { TopicService } from './services'
import { DateService } from './services'
import { AlbumService } from './services'

const services = [
  BreadcrumbService,
  MessagesService,
  PrettySizeService,
  AppCacheService,
  AppToastrService,
  AppTranslateService,
  ConfirmationService,
  ViewService,
  AuthService,
  MeService,
  UserService,
  PersonService,
  TenantService,
  BatchJobService,
  StoreService,

  // custom per app
  StudentService,
  GuardianService,
  TeacherService,
  SupervisorService,
  EmployeeService,
  TermService,
  StageService,
  SubjectService,
  DivisionService,
  TopicService,
  DateService,
  AlbumService,
  ReportCardsService
]

// MAIN PAGES
import { LayoutsAuthComponent } from './layouts/auth/auth'
import { HomeComponent, ReportCardComponent, SubjectReportCardsComponent } from './pages'
import { LoginComponent } from './pages'
import { ProfileComponent } from './pages'
import { FileJobsComponent } from './pages'
import { BatchJobComponent } from './pages'
import { ReportsComponent } from './pages'
// custom per app
import { ForcePasswordComponent } from './pages'
import { TermsComponent, TermComponent } from './pages'
import { SubjectComponent, SubjectsComponent } from './pages'
import { DivisionsComponent, DivisionComponent } from './pages'
import { StudentComponent, StudentsComponent } from './pages'
import { GuardianComponent, GuardiansComponent } from './pages'
import { TeacherComponent, TeachersComponent } from './pages'
import { SupervisorComponent, SupervisorsComponent } from './pages'
import { EmployeeComponent, EmployeesComponent } from './pages'
import { TopicComponent, TopicsComponent } from './pages'
import { AlbumsComponent, AlbumComponent } from './pages'
import { ExamComponent } from './pages'
import { RatingComponent } from './pages'
import { FeedComponent } from './pages'
import { AttendanceComponent } from './pages'
import { GradesComponent, GradeComponent } from './pages'
import { PromotionComponent } from './pages'

const pages = [
  HomeComponent,
  LayoutsAuthComponent,
  LoginComponent,
  ProfileComponent,
  BatchJobComponent,
  FileJobsComponent,
  ReportsComponent,

  // custom per app
  ForcePasswordComponent,
  TermsComponent,
  TermComponent,
  SubjectComponent,
  SubjectsComponent,
  DivisionComponent,
  ReportCardComponent,
  SubjectReportCardsComponent,
  DivisionsComponent,
  StudentComponent,
  StudentsComponent,
  GuardianComponent,
  GuardiansComponent,
  TeacherComponent,
  TeachersComponent,
  SupervisorComponent,
  SupervisorsComponent,
  EmployeeComponent,
  EmployeesComponent,
  TopicComponent,
  TopicsComponent,
  AlbumsComponent,
  AlbumComponent,
  ExamComponent,
  RatingComponent,
  FeedComponent,
  AttendanceComponent,
  GradesComponent,
  GradeComponent,
  PromotionComponent
]

// PIPES
import { ShortDateTimePipe, ShortDatePipe, TimeAgoPipe, PrettySizePipe } from './pipes'
import { OptionalPipe, TranslateErrorPipe, BoolPipe } from './pipes'
import { UsernamePipe, TenantRolePipe } from './pipes'
import { BatchJobTypePipe, BatchJobStatusPipe, BatchJobStatusLabelPipe } from './pipes'
// custom per app
import { FirstPipe, TimePipe, KeysPipe } from './pipes'
import { ContactTagLabelPipe, ContactTagPipe, ContactTypePipe, ContactTypeIconPipe } from './pipes'
import { GenderLabelPipe, GenderPipe, SchoolShiftPipe, SchoolShiftLabelPipe } from './pipes'
import { UsersArrayPipe } from './pipes'
import { TopicAttendanceLabelPipe, TopicTypePipe, RecipientTypePipe } from './pipes'
import { TermNamePipe } from './pipes'
import { StudentReportCardsTab } from './pages/student/student-report-cards-tab'
import { ReportCardsService } from './services/report-cards.service'
import { RecipientsListModal } from './widgets/recipients-list-modal/recipients-list-modal';

const pipes = [
  ShortDatePipe,
  ShortDateTimePipe,
  TimeAgoPipe,
  PrettySizePipe,
  OptionalPipe,
  TranslateErrorPipe,
  BoolPipe,
  UsernamePipe,
  TenantRolePipe,
  BatchJobTypePipe,
  BatchJobStatusPipe,
  BatchJobStatusLabelPipe,
  // custom per app
  FirstPipe,
  TimePipe,
  KeysPipe,
  ContactTagLabelPipe,
  ContactTagPipe,
  ContactTypeIconPipe,
  ContactTypePipe,
  GenderLabelPipe,
  GenderPipe,
  SchoolShiftPipe,
  SchoolShiftLabelPipe,
  UsersArrayPipe,
  TopicAttendanceLabelPipe,
  TopicTypePipe,
  RecipientTypePipe,
  TermNamePipe
]

export function declarations () {
  return [
    ...widgets,
    ...pages,
    ...pipes
  ]
}

// APP MODULE
@NgModule( {
  imports: [
    ...modules
  ],
  declarations: declarations(),
  providers: [
    ...services,
    ...guards
  ],
  entryComponents: [RecipientsListModal],
  bootstrap: [AppComponent]
})
export class AppModule { }
