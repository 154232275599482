<div bsModal #confirmationModal="bs-modal" class="modal fade" role="dialog" aria-hidden="true" [config]="settings">
  <div class="modal-dialog" [ngClass]="isInputForm ? 'modal-md' : 'modal-sm'">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title pull-left"><strong>{{ title | translate }}</strong></h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <h5 class="text-center">{{ message | translate }}</h5>
        <div>
          <input id="ModalInput" *ngIf="isInputForm" class="form-control" #myInput placeholder="Valor..." [(ngModel)]="value">
        </div>
        <h5 class="text-center" *ngIf="isInputForm" style="margin-top: 32px;">Comentarios</h5>
        <div>
          <textarea id="CommentsInput" name="comments" *ngIf="isInputForm" class="form-control"
            placeholder="Añadir comentarios a la valoración..." [(ngModel)]="comments"></textarea>
        </div>
        <h5 class="text-center" *ngIf="isInputForm" style="margin-top: 32px;">Adjuntar archivos</h5>
        <div *ngIf="isInputForm">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let attachment of attachments; index as i">
              <a href="{{ attachment.secure_url }}" target="_blank">
                <span>{{ attachment.original_filename }}</span>
                <span class="text-muted">&nbsp;({{ attachment.bytes | prettySize }})</span>
              </a>
              <a>
                <i class="fa fa-times action-icon action-icon-danger" aria-hidden="true"
                  (click)="removeAttachment(i)" tooltip="{{ 'COMMON.BTN_REMOVE' | translate }}">
                </i>
              </a>
            </li>
            <li class="list-group-item text-center text-muted" *ngIf="!attachments.length">
              <span>{{ 'TOPIC.NO_ATTACHMENTS' | translate }}</span>
            </li>
          </ul>
          <upload-file [preset]="preset" [tags]="tags"
            (onUpload)="onAttachmentUpload($event)"></upload-file>
        </div>
        <div class="modal-footer">
          <button type="button" class="pull-left btn btn-success btn-lg" (click)="confirm()">{{ 'COMMON.BTN_YES' |
            translate }}</button>
          <button type="button" class="btn btn-danger btn-lg" (click)="cancel()">{{ 'COMMON.BTN_NO' | translate
            }}</button>
        </div>
      </div>
    </div>
  </div>
</div>