<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a (click)="goBack()" class="btn btn-sm">
      <i [tooltip]="'COMMON.BTN_BACK' | translate" aria-hidden="true"
         class="fa fa-arrow-left"
         placement="bottom"></i>
    </a>
  </h1>
</section>

<section *ngIf="isLoading" class="content text-center text-muted">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section *ngIf="!isLoading && !noReportCardsError && !noSubjectError" class="content">
  <div>
    <div class="row mb-3" style="margin-bottom: 8px">
      <div class="col-sm-2">
        <div>
          <b>Asignatura</b>
        </div>
        <div>
          <span>{{ this.subject.name }}</span>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 16px">
      <div class="col-sm-2">
        <div>
          <b>Ciclo Lectivo</b>
        </div>
        <div>
          {{ this.reportCard.termDescription }}
        </div>
      </div>
      <div class="col-sm-2">
        <div>
          <b>Etapa</b>
        </div>
        <div>
          {{ this.reportCard.stageName }}
        </div>
      </div>
      <div class="col-sm-2">
        <div>
          <b>Curso</b>
        </div>
        <div>
          {{ this.reportCard.divisionName }}
        </div>
      </div>
      <div class="col-sm-6">
        <div>
          <b>Docentes</b>
        </div>
        <div>
          <span *ngFor="let teacher of teachers; let isLast=last">
            {{ teacher.name.full }}{{isLast ? '' : ', '}}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="currentReportCardStageState" style="margin-bottom: 16px">
      <button (click)="save()" [disabled]="!((currentReportCardStageState === 'Pending' || currentReportCardStageState === 'Ready') && actionsLength() > 0)"
              class="btn btn-success btn-lg ladda-button ng-star-inserted" data-spinner-lines="12" type="submit">
        <span class="ladda-label">Guardar</span><span class="ladda-spinner"></span>
      </button>
      <span *ngIf="isAdmin">
        <button (click)="approve()" [disabled]="actionsLength() > 0"
                [tooltip]="currentReportCardStageName" class="btn btn-info btn-lg ladda-button ng-star-inserted"
                data-spinner-lines="12"
                type="submit"
                *ngIf="currentReportCardStageState!== 'Approved'"
        >
        <span class="ladda-label">Marcar Revisado</span><span class="ladda-spinner"></span>
      </button>
      <button (click)="disapprove()"
              [tooltip]="currentReportCardStageName" class="btn btn-danger btn-lg ladda-button ng-star-inserted"
              data-spinner-lines="12"
              type="submit" *ngIf="currentReportCardStageState === 'Approved'">
        <span class="ladda-label">Marcar No Revisado</span><span class="ladda-spinner"></span>
      </button>
      <button (click)="publish()" [disabled]="currentReportCardStageState !== 'Approved'"
              [tooltip]="currentReportCardStageName"
              class="btn btn-warning btn-lg ladda-button ng-star-inserted" data-spinner-lines="12" type="submit">
        <span class="ladda-label">Publicar</span><span class="ladda-spinner"></span>
      </button>
      </span>
      <button (click)="cancel()" class="btn btn-default btn-lg" type="button">
        Cancelar
      </button>
    </div>
  </div>
  <div *ngIf="showMissing">
    <b>Error:</b> Faltan calificar las siguientes asignaturas:
    <ul>
      <li *ngFor="let subject of missingSubjects">
        {{ subject.name }} <b><a [href]="'/divisions/'+ division._id +'/courses/' + subject.courseId + '/reportCards'">Calificar</a></b>
      </li>
    </ul>
  </div>
  <div *ngIf="studentsWithoutReportCard.length > 0">
    <b>Advertencia:</b> Los siguientes alumnos no tienen boletines creados:
    <ul>
      <li *ngFor="let student of studentsWithoutReportCard">
        {{ student.name.first }} {{ student.name.last }}
      </li>
    </ul>
  </div>
  <div>
    <ngx-datatable
      [columns]="columns"
      [rows]="rows">
      <ngx-datatable-column
        *ngFor="let column of columns; let i = index;"
        [sortable]="false"
        name="{{column.name}}"
        prop="{{column.prop}}"
      >
        <ng-template
          *ngIf="column.prop.startsWith('grade')"
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template>
          <span *ngIf="!value.grade && value.allowed">
            <a (click)="editCell(value)" href="javascript:void(0)"><b>Calificar</b></a>
          </span>

          <span *ngIf="!value.grade && !value.allowed">
            <span>-</span>
          </span>

          <span *ngIf="value.grade && !value.allowed">
            <span>{{value.grade}}</span>
          </span>

          <span *ngIf="value.grade && value.allowed">
            <span>{{value.grade}}</span>
            <a (click)="editCell(value)" href="javascript:void(0)" style="margin-inline-start: 8px;"><b>Editar</b></a>
          </span>

        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</section>

<section *ngIf="noReportCardsError" class="content">
  <b>Error:</b> No hay boletines creados para este curso.
</section>

<section *ngIf="noSubjectError" class="content">
  <b>Error:</b> No se encontró la asignatura en el boletín.
</section>
