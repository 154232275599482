var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CrudService } from './crud.service';
var StageDependantCrudService = /** @class */ (function (_super) {
    __extends(StageDependantCrudService, _super);
    function StageDependantCrudService(http, urlSpecific, authService, termOnly, typeDeserializer) {
        if (termOnly === void 0) { termOnly = false; }
        if (typeDeserializer === void 0) { typeDeserializer = function (e) { return e; }; }
        var _this = _super.call(this, http, urlSpecific, typeDeserializer) || this;
        _this.http = http;
        _this.urlSpecific = urlSpecific;
        _this.authService = authService;
        _this.termOnly = termOnly;
        _this.getByIds = function (ids) { return __awaiter(_this, void 0, void 0, function () {
            var list, _i, ids_1, id, element;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        list = [];
                        _i = 0, ids_1 = ids;
                        _a.label = 1;
                    case 1:
                        if (!(_i < ids_1.length)) return [3 /*break*/, 4];
                        id = ids_1[_i];
                        return [4 /*yield*/, promisify(this.getById(id))];
                    case 2:
                        element = (_a.sent());
                        list.push(element);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, list];
                }
            });
        }); };
        return _this;
    }
    StageDependantCrudService.prototype.search = function (params) {
        this.updateUrl();
        return _super.prototype.search.call(this, params);
    };
    StageDependantCrudService.prototype.getById = function (id, params) {
        this.updateUrl();
        return _super.prototype.getById.call(this, id, params);
    };
    StageDependantCrudService.prototype.create = function (object) {
        this.updateUrl();
        return _super.prototype.create.call(this, object);
    };
    StageDependantCrudService.prototype.update = function (id, object) {
        this.updateUrl();
        return _super.prototype.update.call(this, id, object);
    };
    StageDependantCrudService.prototype.remove = function (id) {
        this.updateUrl();
        return _super.prototype.remove.call(this, id);
    };
    StageDependantCrudService.prototype.updateUrl = function () {
        var term = this.authService.getSelectedTerm();
        this.url = this.apiUrl + "/terms/" + term._id;
        if (!this.termOnly) {
            var stage = this.authService.getSelectedStage();
            this.url += "/stages/" + stage._id;
        }
        this.url += "" + this.urlSpecific;
    };
    return StageDependantCrudService;
}(CrudService));
export { StageDependantCrudService };
function promisify(observable) {
    return new Promise(function (resolve, reject) {
        observable.subscribe(resolve, reject);
    });
}
