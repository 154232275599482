import { Component } from '@angular/core'
import {
  ReportCard,
  ReportCardActionJSON,
  ReportCardsService,
  ReportCardStageDataRow,
} from '../../services/report-cards.service'
import { ActivatedRoute, Params } from '@angular/router'
import { AuthService, ViewService, ConfirmationService, DivisionService, TeacherService, SubjectService } from '../../services'
import { Observable } from 'rxjs'
import { Division, Subject, Teacher, User } from '../../interfaces'

interface ReportCardActionsByReporCardId {
  [key: string]: ReportCardActionJSON;
}

interface CellValue {
  allowed: boolean,
  grade: string | null | undefined,
  comments: string | null | undefined,
  attachments: any[] | null | undefined,
  reportCardId: string,
  stageIndex: number,
  subjectId: string,
}

interface MissingSubject {
  name: string;
  courseId: string;
}

@Component({
  selector: 'app-subject-report-cards',
  templateUrl: './subject-report-cards.component.html'
})
export class SubjectReportCardsComponent {
  public pageData: any = {}
  public isLoading: boolean = true
  public reportCard!: ReportCard

  isAdmin!: boolean

  currentReportCardStageState: string | null = null
  currentReportCardStageName: string | null = null
  currentReportCardStageIndex: number | null = null

  rows: any[] = []

  columns: any[] = []
  missing: Set<string> | null = null
  missingSubjects: MissingSubject[] = []

  changes: {
    [key: number]: {
      [key: string]: { value: string | undefined; comments: string | undefined; attachments: any[] };
    };
  } = {}

  actions: ReportCardActionsByReporCardId = {}
  division!: Division
  students!: User[]
  course!: any
  reportCards!: ReportCard[]
  teachers!: Teacher[]
  subject!: Subject
  studentsWithoutReportCard: User[] = []
  showMissing: boolean = false
  courses: any[] = []
  noReportCardsError: boolean = false
  noSubjectError: boolean = false

  constructor(
    private readonly viewService: ViewService,
    private readonly divisionService: DivisionService,
    private readonly reportCardsService: ReportCardsService,
    private readonly teacherService: TeacherService,
    private readonly subjectService: SubjectService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly confirmationModal: ConfirmationService
  ) {
    this.route.data
      .subscribe(pageData => {
        this.pageData = pageData
      })
  }

  public ngOnInit() {
    this.fetchRouteParams()
  }

  public goBack = () => this.viewService.goBack()

  public isDirty = (): boolean => false

  editCell(value: CellValue) {
    this.confirmationModal.show("REPORT_CARDS.NEW_VALUE_GRADE", "DIVISION.ADD_VALUE", true, true, { value: value.grade || "", attachments: value.attachments || [], comments: value.comments || "" })
      .subscribe(
        (hasData: boolean) => {
          if (hasData) {
            let payload = this.confirmationModal.getPayload()
            value.grade = payload.value
            value.comments = payload.comments
            value.attachments = payload.attachments
            if (!this.changes[value.stageIndex]) {
              this.changes[value.stageIndex] = {}
            }
            this.changes[value.stageIndex][value.reportCardId] = payload
            this.updateActions()
          }
          this.confirmationModal.clearInputValue()
        })
  }

  async save() {
    const promises: Promise<void>[] = []
    for (const reportCardId in this.actions) {
      promises.push(this.reportCardsService.applyActions(reportCardId, [this.actions[reportCardId]]))
    }
    await Promise.all(promises)

    this.actions = {}
    this.changes = {}
    this.fetchRouteParams()
  }

  cancel() {
    this.goBack()
  }

  openPreview() {
    window.open(this.reportCardsService.baseUrl + '/report-cards/preview/' + this.reportCard._id, "_blank")
  }

  async approve() {
    if (this.missing?.size !== 0) {
      this.showMissing = true;
      return
    }

    await Promise.all(this.reportCards.map(async (reportCard) => {
      try {
        await this.reportCardsService.applyActions(reportCard._id!, [
          {
            payload: {
              stageIndex: `${this.currentReportCardStageIndex}`,
            }, type: 'Approve'
          }
        ])
      } catch (e) {
        console.error(e)
      }
    }))

    this.actions = {}
    this.changes = {}
    this.fetchRouteParams()
  }

  actionsLength(): number {
    return Object.keys(this.actions).length
  }

  async publish() {
    await Promise.all(this.reportCards.map(async (reportCard) => {
      try {
        await this.reportCardsService.applyActions(reportCard._id!, [
          {
            payload: {
              stageIndex: `${this.currentReportCardStageIndex}`,
            }, type: 'PublishTerm'
          }
        ])
      } catch (e) {
        console.error(e)
      }
    }))
    this.actions = {}
    this.changes = {}
    this.fetchRouteParams()
  }

  private updateActions() {
    const actions: ReportCardActionsByReporCardId = {}

    for (let stageIndex in this.changes) {
      if (this.changes.hasOwnProperty(stageIndex)) {
        let stageChanges = this.changes[stageIndex]
        for (let reportCardId in stageChanges) {
          if (stageChanges.hasOwnProperty(reportCardId)) {
            let payload = stageChanges[reportCardId]
            actions[reportCardId] = {
              payload: {
                grade: payload.value || "",
                comments: payload.comments || "",
                attachments: payload.attachments || [],
                stageIndex: stageIndex,
                subjectId: this.subject._id,
                reportCardId: reportCardId
              },
              type: 'SetGrade'
            }
          }
        }
      }
    }

    this.actions = actions
    console.log(this, this.actions)
  }

  async disapprove() {
    await Promise.all(this.reportCards.map(async (reportCard) => {
      try {
        await this.reportCardsService.applyActions(reportCard._id!, [
          {
            payload: {
              stageIndex: `${this.currentReportCardStageIndex}`,
            }, type: 'Disapprove'
          }
        ])
      } catch (e) {
        console.error(e)
      }
    }))
    this.actions = {}
    this.changes = {}
    this.fetchRouteParams()
  }

  async createSubject() {
    await Promise.all(this.reportCards.map(async (reportCard) => {
      try {
        await this.reportCardsService.applyActions(reportCard._id!, [
          {
            payload: {
              subjectId: this.subject._id,
            }, type: 'AddSubject'
          }
        ])
      } catch (e) {
        console.error(e)
      }
    }))
    this.actions = {}
    this.changes = {}
    this.fetchRouteParams()
  }

  public fetchRouteParams() {
    this.route.params
      .subscribe(async (params: Params) => {
        this.isLoading = true

        let currentRole = this.authService.getCurrentUser().currentRole
        this.isAdmin = currentRole === "owner" || currentRole === "supervisor"

        const [division, students, courses, subjectReportCards] = await Promise.all([
          promisify(this.divisionService.getById(params.divisionId)),
          promisify(this.divisionService.getStudents(params.divisionId)),
          promisify(this.divisionService.getCourses(params.divisionId)),
          this.reportCardsService.getSubjectReportCardsForDivisionAndCourse(params.divisionId, params.courseId),
        ]);
        this.division = division;
        this.students = students as User[];
        this.courses = courses.list;
        this.course = this.courses.find(course => course._id === params.courseId);
        this.reportCards = subjectReportCards.reportCards;
        const [teachers, subjectQ] = await Promise.all([
          this.teacherService.getByIds(this.course.teachers),
          promisify(this.subjectService.getById(this.course.subject)),
        ]);
        this.teachers = teachers;
        this.subject = subjectQ;
        this.reportCard = this.reportCards[0]

        if (this.reportCards.length === 0) {
          this.noReportCardsError = true
          this.isLoading = false
          return;
        }

        console.log("Subject Report Cards", subjectReportCards)

        console.log("Report cards", this.reportCards)
        console.log("Division", this.division)
        console.log("Students", this.students)
        console.log("Courses", this.courses)
        console.log("Course", this.course)
        console.log("Teachers", this.teachers)
        console.log("Subject", this.subject)

        const subject = this.reportCard.subjects.find(e => e.subjectId === this.subject._id)

        if (!subject) {
          await this.createSubject();
          return
        }

        this.columns = [
          { name: 'Alumnos', prop: 'studentFullName', sortable: false },
          ...this.reportCard.stages.map((stage, index) => ({ name: stage.name, prop: 'grade_' + index, sortable: false }))
        ]

        this.currentReportCardStageIndex = null
        this.rows = [
          ...this.students.map((student) => {
            let map: any = {}
            let reportCard = this.reportCards.find(e => e.studentId === student._id)

            if (reportCard) {
              reportCard!.stages.forEach((stage, index) => {
                if ((stage.state === 'Pending' || stage.state === 'Ready' || stage.state === 'Approved') && stage.name !== 'Promedio') {
                  if (this.currentReportCardStageIndex === null || index < this.currentReportCardStageIndex) {
                    this.currentReportCardStageIndex = index;
                    this.currentReportCardStageName = stage.name;
                    this.currentReportCardStageState = stage.state;
                  }
                }
                const isAllowed: boolean = (stage.state === "Pending" || stage.state === "Ready") && (currentRole === 'owner'
                  || currentRole === 'supervisor'
                  || subject.teachers.find((teacher) => teacher.teacherId === this.authService.getCurrentUser()._id) !== undefined)
                const grade: ReportCardStageDataRow | undefined = stage.data.find((s) => s.subjectId === subject.subjectId)
                map["grade_" + index] = {
                  stageIndex: index,
                  subjectId: subject.subjectId,
                  reportCardId: reportCard!._id!,
                  allowed: isAllowed,
                  grade: grade ? grade.value : null,
                  // @ts-ignore
                  comments: grade?.comments || "",
                  // @ts-ignore
                  attachments: grade?.attachments || [],
                }
              })
            } else {
              this.studentsWithoutReportCard.push(student)
            }

            return ({
              studentFullName: student.name.first + " " + student.name.last,
              ...map
            })
          })
        ]

        if (this.currentReportCardStageIndex != null) {
          this.missing = this.reportCards.map(r => {
            const studentName = r.studentName.first + r.studentName.last;
            const state = r.stages[this.currentReportCardStageIndex!].state;
            const all = r.subjects.map(s => ({ id: s.subjectId, name: s.subjectName }))
            const graded = r.stages[this.currentReportCardStageIndex!].data.map(g => g.subjectId)
            const missing = all.filter((subject) => !graded.find((g) => g === subject.id)).map(e => e.name)
            return { studentName, state, missing }
          }).map(e => e.missing).reduce((set, subjectNames) => {
            for (const subjectName of subjectNames) {
              set.add(subjectName)
            }
            return set
          }, new Set<string>())
          this.missingSubjects = this.reportCard.subjects.filter(s => this.missing?.has(s.subjectName)).map(s => ({
            name: s.subjectName,
            courseId: this.courses.find(c => c.subject === s.subjectId)?._id as string || ""
          }))
          console.log(this.missing)
          console.log("Missing Subjects", this.missingSubjects)
        }

        this.isLoading = false
      })
  }
}

function promisify<T>(observable: Observable<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    observable.subscribe(resolve, reject)
  })
}
