import { Component } from '@angular/core'
import {
  ReportCard,
  ReportCardAction,
  ReportCardsService,
  ReportCardStage,
  ReportCardStageDataRow
} from '../../services/report-cards.service'
import { ActivatedRoute, Params } from '@angular/router'
import { AuthService, ViewService, ConfirmationService } from '../../services'

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html'
})
export class ReportCardComponent {
  public pageData: any = {}
  public isLoading: boolean = true
  public reportCard!: ReportCard

  isAdmin!: boolean

  currentReportCardStage: ReportCardStage | null = null

  rows: any[] = []

  columns: any[] = []

  changes: any = {}

  actions: ReportCardAction[] = []

  constructor (
    private readonly viewService: ViewService,
    private readonly reportCardsService: ReportCardsService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly confirmationModal: ConfirmationService
  ) {
    this.route.data
      .subscribe(pageData => {
        this.pageData = pageData
      })
  }

  public ngOnInit () {
    this.fetchRouteParams()
  }

  public goBack = () => this.viewService.goBack()

  public isDirty = (): boolean => false

  editCell (value: any) {
    this.confirmationModal.show("REPORT_CARDS.NEW_VALUE_GRADE", "DIVISION.ADD_VALUE", true, true, {value: value.grade || "", attachments: value.attachments || [], comments: value.comments || ""})
    .subscribe(
      hasData => {
        if (hasData) {
          let payload = this.confirmationModal.getPayload()
          value.grade = payload.value
          value.comments = payload.comments
          value.attachments = payload.attachments
          if (!this.changes[value.stageIndex]) {
            this.changes[value.stageIndex] = {}
          }
          this.changes[value.stageIndex][value.subjectId] = payload
          this.updateActions()
        }
        this.confirmationModal.clearInputValue()
    })
  }

  async save () {
    await this.reportCardsService.applyActions(this.reportCard._id!, this.actions)
    this.actions = []
    this.changes={}
    this.fetchRouteParams()
  }

  cancel () {
    this.goBack()
  }

  openPreview () {
    window.open(this.reportCardsService.baseUrl + '/report-cards/preview/' + this.reportCard._id, "_blank")
  }

  async approve () {
    await this.reportCardsService.applyActions(this.reportCard._id!, [
      {
        payload: {
          stageIndex: `${this.reportCard.stages.indexOf(this.currentReportCardStage!)}`,
        }, type: 'Approve'
      }
    ])
    this.actions = []
    this.changes={}
    this.fetchRouteParams()
  }

  async publish () {
    await this.reportCardsService.applyActions(this.reportCard._id!, [
      {
        payload: {
          stageIndex: `${this.reportCard.stages.indexOf(this.currentReportCardStage!)}`,
        }, type: 'PublishTerm'
      }
    ])
    this.actions = []
    this.changes={}
    this.fetchRouteParams()
  }

  private updateActions () {
    const actions: any[] = []

    for (let stageIndex in this.changes) {
      if (this.changes.hasOwnProperty(stageIndex)) {
        let stageChanges = this.changes[stageIndex]
        for (let subjectId in stageChanges) {
          if (stageChanges.hasOwnProperty(subjectId)) {
            let payload = stageChanges[subjectId]
            actions.push({
              payload: {
                grade: payload.value || "",
                comments: payload.comments || "",
                attachments: payload.attachments || [],
                stageIndex: stageIndex,
                subjectId: subjectId
              },
              type: 'SetGrade'
            })
          }
        }
      }
    }

    this.actions = actions
  }

  async disapprove () {
    await this.reportCardsService.applyActions(this.reportCard._id!, [
      {
        payload: {
          stageIndex: `${this.reportCard.stages.indexOf(this.currentReportCardStage!)}`,
        }, type: 'Disapprove'
      }
    ])
    this.actions = []
    this.changes={}
    this.fetchRouteParams()
  }

  public fetchRouteParams(){
    this.route.params
    .subscribe(async (params: Params) => {
      this.isLoading = true
      let currentRole = this.authService.getCurrentUser().currentRole
      this.isAdmin = currentRole === "owner" || currentRole === "supervisor"
      const reportCardId = params['id']
      this.reportCard = await this.reportCardsService.getReportCardById(reportCardId).toPromise()
      this.columns = [
        {name: 'Asignaturas', prop: 'subject', sortable: false},
        ...this.reportCard.stages.map((stage, index) => ({name: stage.name, prop: 'grade_' + index, sortable: false}))
      ]
      this.rows = [
        ...this.reportCard.subjects.map((subject) => {
          let map: any = {}
          this.reportCard.stages.forEach((stage, index) => {
            if (stage.state === 'Pending' || stage.state === 'Ready' || stage.state === 'Approved') {
              this.currentReportCardStage = stage
            }else{
            //  this.currentReportCardStage = null
            }
            if(stage.state === 'Approved' && stage.name == 'Promedio'){
              this.currentReportCardStage = null;
            }
            const isAllowed: boolean = (stage.state === "Pending" || stage.state === "Ready") && (currentRole === 'owner'
              || currentRole === 'supervisor'
              || subject.teachers.find((teacher) => teacher.teacherId === this.authService.getCurrentUser()._id) !== undefined)
            const grade: ReportCardStageDataRow | undefined = stage.data.find((s) => s.subjectId === subject.subjectId)
            map["grade_" + index] = {
              stageIndex: index,
              subjectId: subject.subjectId,
              allowed: isAllowed,
              grade: grade ? grade.value : null,
              // @ts-ignore
              comments: grade?.comments || "",
              // @ts-ignore
              attachments: grade?.attachments  || [],
            }
          })
          return ({
            subject: subject.subjectName,
            ...map
          })
        })
      ]
      this.isLoading = false
    })
  }
}
