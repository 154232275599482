import { Subject } from 'rxjs/Rx'
import { Observable } from 'rxjs/Observable'
import { ModalDirective } from 'ngx-bootstrap'
import { environment } from '../../../environments/environment'
import { Component, OnInit, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core'

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation.modal.html'
})

export class ConfirmationModal implements OnInit {
  @Output() onInitialized = new EventEmitter<ConfirmationModal>()


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'confirmationModal' has no initializer an... Remove this comment to see the full error message
  @ViewChild('confirmationModal') confirmationModal: ModalDirective

  @ViewChild("myInput") private _inputElement!: ElementRef;


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'message' has no initializer and is not d... Remove this comment to see the full error message
  public message: string
  public title: string | undefined
  public settings: any

  public tags = 'attachment topic'
  public preset: string = environment.CLOUDINARY.ATTACHMENTS_PRESET

  public isInputForm: boolean = false

  public allowCommentsAndAttachments: boolean = false

  public attachments: any[] = []

  public value: string = ""
  public comments: string = ""

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'proxy' has no initializer and is not def... Remove this comment to see the full error message
  private proxy: Subject<boolean>


  ngOnInit() {
    this.settings = {
      backdrop: 'static',
      keyboard: true,
      class: 'modal-large',
    }
    this.onInitialized.emit(this)
  }

  public show(content: string, title?: string | undefined, isInputForm?: boolean, allowCommentsAndAttachments: boolean = false, payload: { value: string | undefined; comments: string | undefined; attachments: any[] } | undefined = undefined): Observable<boolean> {
    if (this.proxy) {
      return Observable.throw(new Error('There is already another modal displayed'))
    }

    if (title === undefined) {
      this.title = 'COMMON.CONFIRM_TITLE'
    } else {
      this.title = title;
    }

    this.isInputForm = isInputForm || false

    this.allowCommentsAndAttachments = allowCommentsAndAttachments;

    console.log("payload", payload)
    if (payload) {
      this.attachments = payload.attachments
      this.comments = payload.comments || ""
      this.value = payload.value || ""
    }

    this.message = content
    this.proxy = new Subject<boolean>()
    this.confirmationModal.show()
    setTimeout(() => {
      this._inputElement.nativeElement.focus({ focusVisible: true });
      console.log('focus', this._inputElement.nativeElement)
    }, 200)
    return this.proxy
  }

  public hide() {
    this.cancel()
  }

  // meant to be used internally, triggered by Confirm button
  public confirm() {
    this.confirmationModal.hide()
    this.callOff(true)
  }

  // meant to be used internally, triggered by Cancel button
  public cancel() {
    this.confirmationModal.hide()
    this.callOff()
  }

  private callOff(result: boolean = false) {
    this.proxy.next(result)
    this.proxy.complete()


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Subjec... Remove this comment to see the full error message
    this.proxy = undefined
  }

  public onAttachmentUpload(res: any) {
    this.attachments.push(res)
  }

  public removeAttachment(index: any) {
    this.attachments.splice(index, 1)
  }

  public getInputValue() {
    const input = document.getElementById('ModalInput') as HTMLInputElement | null
    return input?.value
  }

  public getCommentsValue() {
    const input = document.getElementById('CommentsInput') as HTMLInputElement | null
    return input?.value
  }

  public getPayload(): { value: string | undefined; comments: string | undefined; attachments: any[] } {
    return {
      value: this.getInputValue(),
      comments: this.getCommentsValue(),
      attachments: this.attachments,
    }
  }

  public clearInputValue() {
    this.value = ""
    this.comments = ""
    this.attachments = [];
  }
}
