
// @ts-expect-error ts-migrate(6133) FIXME: 'PipeTransform' is declared but its value is never... Remove this comment to see the full error message
import { Component, ViewChild, Input, Output, TemplateRef, EventEmitter, PipeTransform } from '@angular/core'

import * as _ from 'lodash-es'
import { SortDirection } from '@swimlane/ngx-datatable'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'appdatatable',
  templateUrl: './app-datatable.component.html',
  styleUrls: ['./app-datatable.component.styl'],
  providers: [TranslatePipe]
})
export class AppDataTableComponent {

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'colHeader' has no initializer and is not... Remove this comment to see the full error message
  @ViewChild('i18nColumnHeader') colHeader: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'centeredColHeader' has no initializer an... Remove this comment to see the full error message
  @ViewChild('i18nCenteredColumnHeader') centeredColHeader: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'hideColumnHeader' has no initializer and... Remove this comment to see the full error message
  @ViewChild('hideColumnHeader') hideColumnHeader: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'boolCell' has no initializer and is not ... Remove this comment to see the full error message
  @ViewChild('boolCell') boolCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'devicesCell' has no initializer and is n... Remove this comment to see the full error message
  @ViewChild('devicesCell') devicesCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'defaultCell' has no initializer and is n... Remove this comment to see the full error message
  @ViewChild('defaultCell') defaultCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'currencyCell' has no initializer and is ... Remove this comment to see the full error message
  @ViewChild('currencyCell') currencyCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'defaultLabelCell' has no initializer and... Remove this comment to see the full error message
  @ViewChild('defaultLabelCell') defaultLabelCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'defaultLabelsCell' has no initializer an... Remove this comment to see the full error message
  @ViewChild('defaultLabelsCell') defaultLabelsCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'defaultBadgeCell' has no initializer and... Remove this comment to see the full error message
  @ViewChild('defaultBadgeCell') defaultBadgeCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'infoBadgeCell' has no initializer and is... Remove this comment to see the full error message
  @ViewChild('infoBadgeCell') infoBadgeCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'warningBadgeCell' has no initializer and... Remove this comment to see the full error message
  @ViewChild('warningBadgeCell') warningBadgeCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'dangerBadgeCell' has no initializer and ... Remove this comment to see the full error message
  @ViewChild('dangerBadgeCell') dangerBadgeCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'hrefRouterCell' has no initializer and i... Remove this comment to see the full error message
  @ViewChild('hrefRouterCell') hrefRouterCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'hrefClickCell' has no initializer and is... Remove this comment to see the full error message
  @ViewChild('hrefClickCell') hrefClickCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'hrefClickCell' has no initializer and is... Remove this comment to see the full error message
  @ViewChild('hrefClickCellCalificar') hrefClickCellCalificar: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'iconActionsCell' has no initializer and ... Remove this comment to see the full error message
  @ViewChild('iconActionsCell') iconActionsCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'memberTenantRole' has no initializer and... Remove this comment to see the full error message
  @ViewChild('memberTenantRole') memberTenantRole: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'batchJobStatusCell' has no initializer a... Remove this comment to see the full error message
  @ViewChild('batchJobStatusCell') batchJobStatusCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'batchJobProgressCell' has no initializer... Remove this comment to see the full error message
  @ViewChild('batchJobProgressCell') batchJobProgressCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'errorsDetailCell' has no initializer and... Remove this comment to see the full error message
  @ViewChild('errorsDetailCell') errorsDetailCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'roleCell' has no initializer and is not ... Remove this comment to see the full error message
  @ViewChild('roleCell') roleCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'genderCell' has no initializer and is no... Remove this comment to see the full error message
  @ViewChild('genderCell') genderCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'topicAudienceCell' has no initializer an... Remove this comment to see the full error message
  @ViewChild('topicAudienceCell') topicAudienceCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'topicRecipientsCell' has no initializer ... Remove this comment to see the full error message
  @ViewChild('topicRecipientsCell') topicRecipientsCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'contactCell' has no initializer and is n... Remove this comment to see the full error message
  @ViewChild('contactCell') contactCell: TemplateRef<any>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'schoolShiftCell' has no initializer and ... Remove this comment to see the full error message
  @ViewChild('schoolShiftCell') schoolShiftCell: TemplateRef<any>

  @Output('select') selectEmitter = new EventEmitter<any>()
  @Output('page') pageEmitter = new EventEmitter<any>()
  @Output('sort') sortEmitter = new EventEmitter<any>()

  @Input() loading: boolean = false

  public _iconsConfig: any = {
    sortAscending: 'fa fa-caret-up',
    sortDescending: 'fa fa-caret-down'
  }
  public _sorts: any[] = []
  public _columns = []
  public _rows = []
  public _limit = 0
  public _count: number = 0
  public _page = 1
  private _internalChange = false

  @Input() set page (val: number) {
    this._page = val
  }

  @Input() set rows (vals: any[]) {

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
    this._rows = vals
  }

  @Input() set limit (val: any) {
    this._limit = val
  }

  @Input() set count (val: any) {
    if (val <= this._limit && this._page !== 1) {
      this._internalChange = true
      this._page = 1
    }
    this._count = val
  }

  @Input() set columns (cols: any[]) {
    if (!_.isEmpty(cols)) {
      _.each(cols, (col: any) => {
        if (col) {

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          col.headerTemplate = col.headerTemplate ? this[col.headerTemplate] : this.colHeader

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          col.cellTemplate = col.cellTemplateName ? this[col.cellTemplateName] : this.defaultCell
        }
      })

      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      this._columns = cols
    }
  }

  @Input() set sorts (sorts: any) {
    this._sorts = _.map(sorts, (value: any, key: any) => {
      return {
        prop: key,
        dir: value === 1 ? SortDirection.asc : SortDirection.desc
      }
    })
  }

  public onSelect ({
    selected
  }: any) {
    this.selectEmitter.next(selected[0])
  }

  public onSort (event: any) {
    this._page = 1
    const sort = {
      [event.sorts[0].prop]: event.sorts[0].dir === SortDirection.asc ? 1 : -1
    }
    this.sortEmitter.next({
      sort,
      page: this._page,
      offset: this._page - 1 // backward compatibility
    })
  }

  public onPage (event: any) {
    if (this._internalChange) {
      this._internalChange = false
      return
    }
    event.offset = event.page - 1 // for backward compatibility
    this.pageEmitter.next(event)
  }
}
